<template>
  <CRow>
    <CToaster :autohide="2000">
      <template>           
        <div v-if="showSuccessToast">
          <CToast          
          :show.sync="showSuccessToast"
          color="success"
          >
            {{ successToastMessage }}
          </CToast>
        </div>   
      </template>         
      <template>           
        <div v-if="showErrorToast">
          <CToast          
          :show.sync="showErrorToast"
          color="danger"
          >
            {{ errorToastMessage }}
          </CToast>
        </div>   
      </template>
    </CToaster>
    <CCol col="12" xxl="8">
      <CCard>
        <CCardHeader>
          <CButton class="float-left"  variant="ghost" color="dark" disabled>Create Remediation Item</CButton>
          <CButton class="ml-5 float-right"   @click="openHelp(true)" color="dark" :disabled="true"><span class="material-icons md-18">question_mark</span></CButton>
        </CCardHeader>
        <CCardBody>
          <p class="text-center" v-show="loading">
            <CSpinner />
          </p >   
          <span v-show="!loading">
            <CCardBody>
              <CRow>    
                <CCol col="6">          
                <template>              
                  <div :class="{ 'invalid': $v.selectedLens.$invalid }">
                    <label class="typo__label">Lens</label>
                    <multiselect 
                      v-model="$v.selectedLens.$model"                  
                      :options="lenses" 
                      track-by="lens_alias"
                      label="lens_name" 
                      :searchable="false" 
                      :close-on-select="true"   
                      placeholder="Select the Lens of the Remediation Item."
                      :multiple="false"
                      :hideSelected="false"                  
                      :allowEmpty="false"
                      :loading="loadingLenses"
                      :custom-label="lensSelectCustomLabel"
                      @input="reloadChoices($event)"

                    />                          
                    <label class="typo__label form__label mt-2" > <div style="color:#768192 !important; font-size: 80%"> Select the Lens of the Remediation Item.</div></label>                  
                  </div>
                </template>  
                </CCol>
                <CCol col="6">          
                    <template>              
                        <div :class="{ 'invalid': $v.selectedPillar.$invalid }">
                        <label class="typo__label">Pillar</label>
                        <multiselect 
                          v-model="$v.selectedPillar.$model"                  
                          :options="pillars" 
                          track-by="pillar_id"
                          label="pillar_name"   
                          :searchable="false" 
                          :close-on-select="true"   
                          placeholder="Select Pillar for the Remdiation Item."
                          :multiple="false"
                          :hideSelected="false"                  
                          :allowEmpty="false"
                          :loading="loadingPillars"
                          @input="reloadChoices($event)"
                        />                          
                        <label class="typo__label form__label mt-2" > <div style="color:#768192 !important; font-size: 80%">Select the Pillar of the Remediation Item.</div></label>                  
                      </div>
                    </template>                                 
                  </CCol>
              </CRow>
              <CRow>   
                <CCol col="8">          
                <CInput
                  label="Title"
                  placeholder="Enter a Item title"
                  v-model="$v.itemTitle.$model"                  
                  description="Add meaningful title to the Remediation Item."
                  :is-valid="!$v.itemTitle.$invalid"
                  :disabled="inputDisabled"
                >
                </CInput>
                </CCol>
                <CCol col="2">          

                <CSelect
                  label="Effort"
                  v-model="selectedEffort"
                  :value.sync="selectedEffort"
                  :options="effortList"
                  :disabled="inputDisabled"
                  description="Select the effort in hours for the Remediation Item."
                >
                </CSelect>     
                </CCol>
              </CRow>
              <template>              
                <div :class="{ 'invalid': $v.itemDesc.$invalid }">
                  <label class="typo__label">Description</label>
                    <quill-editor
                      label="Description"
                      ref="myQuillEditor"
                      v-model="$v.itemDesc.$model"                
                      :options="editorOption"
                      :disabled="inputDisabled"
                    />
                    <label class="typo__label form__label mt-2" > <div style="color:#768192 !important; font-size: 80%"> Explain what will be done to remediate the selected choices/findings. The description will show up in the customer-facing report.</div></label>                  
                  </div>
                </template>                
                <template>              
                  <div>
                  <label class="typo__label">Choices</label>
                  <multiselect
                    :value="selectedChoiceList"
                    :options="choices"
                    track-by="id"
                    :searchable="true"
                    :close-on-select="false"
                    :multiple="true"
                    label="name" 
                    :hideSelected="true"
                    @select="selectChoice"
                    @remove="removeChoice"
                    :loading="choicesLoading"
                    :disabled="inputDisabled"
                    :custom-label="selectChoicesCustomLabel"
                    placeholder="Pick choices/findings the Remediation Item will address."
                  >
                   </multiselect>
                   <label class="typo__label form__label mt-2" > <div style="color:#768192 !important; font-size: 80%"> Pick choices/findings the Remediation Item will address.</div></label>                  
                  </div>
                </template>        
            </CCardBody>          
          </span>
        </CCardBody>
        <CCardFooter>
          <CButton color="light" class="col-sm-2" @click="goBack">Back</CButton>          
          <CButton v-on:click="newItem();" color="success" class="float-right col-sm-4" v-show="!nextDisabled" :disabled="nextDisabled" >Next Item</CButton>
          <CButton v-on:click="saveItem();" color="success"  class="float-right col-sm-4" v-show="!inputDisabled" :disabled="!$v.$anyDirty || $v.$invalid">Create Item</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>



<script>
import ASelect from '@/components/forms/ASelect.vue'
import Multiselect from 'vue-multiselect'
import LensService from '@/services/lense.services';
import ItemService from '@/services/item.services';
import ChoiceService from '@/services/choice.services';
import { required, minLength, maxLength, helpers } from 'vuelidate/lib/validators';

export default {
  components: {
    ASelect,
    Multiselect
  },  
  data() {
    return {
      item: {},
      lenses: [],
      pillars: [],      
      choices: [],            
      itemTitle: "",
      itemDesc: "",
      selectedLens: null,
      loadingLenses: true,
      selectedPillar: null,
      loadingPillars: true,
      selectedChoiceList: [],
      selectedEffort: 0.0,
      effortList: [ 0.0, 0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0, 2.25, 2.5, 2.75, 3.0, 3.25, 3.5, 3.75, 4.0  ],
      editorOption: { },
      inputDisabled: false,
      nextDisabled: true,
      loading: true,
      choicesLoading: true,
      showSuccessToast: false,
      showErrorToast: false,
      successToastMessage: "",
      errorToastMessage: ""
    };
  },
  setup: () => ({ v$: useVuelidate() }),    
  validations: {
    selectedLens: {
      required,
    },
    selectedPillar: {
      required,
    },
    itemTitle: {
      required,
      $autoDirty: true,
      minLength: minLength(4),
      maxLength: maxLength(100),
      validator: helpers.regex('alpha', /^[a-zA-Z0-9-_&!#=()\s]+$/)
    },
    itemDesc: {
      minLength: minLength(0),
      maxLength: maxLength(1000),
      // validator: helpers.regex('alpha', /^[a-zA-Z0-9-_&!#=()\s]+$/)
    },
  },

  methods: {
    selectChoicesCustomLabel(choice){
      let questionId = choice.question_title.split(" ")[0]
      return `${choice.name} [${questionId}]`
    },    
    lensSelectCustomLabel(lens){
      return `${lens.lens_name} [${lens.owner}]`
    },    
    newItem() {
        this.itemTitle = null;
        this.selectedChoiceList = [];
        this.itemDesc = "";
        this.selectedEffort = 0.25;
        this.inputDisabled = false;
        this.nextDisabled = true;
    },
    goBack() {
      this.$router.push({path: '/remediation-items'})
    },
    reloadChoices() {
      this.getChoicesByLensePillar()
    },
    selectChoice(selectedChoice) {
      var tmp = {}
      tmp['id'] = selectedChoice.id
      tmp['name'] = selectedChoice.name
      this.selectedChoiceList.push(tmp)
    },
    removeChoice(removedOption, id) {
      const index = this.selectedChoiceList.indexOf(removedOption);
      if (index > -1) {
         this.selectedChoiceList.splice(index, 1);
      }
    },
    // onEditorBlur(quill) {
    //   // console.log('editor blur!', quill)
    // },
    // onEditorFocus(quill) {
    //   // console.log('editor focus!', quill)
    // },
    // onEditorReady(quill) {
    //   // console.log('editor ready!', quill)
    // },
    // onEditorChange({ quill, html, text }) {
    //   // console.log('editor change!', quill, html, text)
    //   // this.itemDesc = html
    // },
    getChoicesByLensePillar() {
      ChoiceService.getChoicesByLensePillar(this.selectedLens.lens_alias, this.selectedPillar.pillar_id).then(
        response => { 
          // clear choice list
          this.choicesLoading = true;
          this.choices = []
          
          for (var key in response){
            var tmp = {}
            tmp["id"] = response[key].choice_id
            tmp["name"] = response[key].choice_name
            tmp["question_id"] = response[key].choice_question_id
            tmp["question_title"] = response[key].choice_question
        
            this.choices.push(tmp)
          }
          this.choicesLoading = false;
        },
        error => {   
          console.log(error);
          this.errorToastMessage = "Error loading Choices.";
          this.showErrorToast = true;
        }           
      );
    },
    async saveItem() {

      var tmp_choices = []
      for (var key in this.selectedChoiceList) {
        tmp_choices.push(this.selectedChoiceList[key].id)
      }

      ItemService.createItem(this.itemDesc, this.itemTitle, this.selectedLens.lens_alias, tmp_choices, this.selectedPillar.pillar_id, this.selectedEffort).then(
        response => { 
          this.inputDisabled = true
          this.nextDisabled = false          
          this.successToastMessage = "Remediation Item created.";
          this.showSuccessToast = true;
        },
        error => {   
          console.log(error);
          this.errorToastMessage = "Error creating Remediation Item.";
          this.showErrorToast = true;
        }
      )
    },   
  },
  created() { },
  async mounted() { 
    await LensService.getLenses().then(
      response => { 
        this.lenses = response.lens_summaries;
        this.selectedLens = this.lenses[0];
        this.loadingLenses = false;
      },
      error => {   
        console.log(error);
        this.errorToastMessage = "Error loading Lenses.";
        this.showErrorToast = true;
      }      
    )
    await LensService.getPillars().then(
      response => { 
        this.pillars = response.pillars;
        this.selectedPillar = this.pillars[0];
        this.loadingPillars = false;
      },
      error => {   
        console.log(error);
        this.errorToastMessage = "Error loading Pillars.";
        this.showErrorToast = true;
      }            
    )
    await ChoiceService.getChoices().then(
      response => { 
        this.choices = response;
      },
      error => {   
        console.log(error);
        this.errorToastMessage = "Error loading Choices.";
        this.showErrorToast = true;
      }
    );
    this.getChoicesByLensePillar();
    this.loading = false;
  },
};

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
